import React, { use, useEffect, useMemo, useState } from "react";
import Image from "next/image";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import AnimationMenu from "plugins/animation-menu";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faCartShopping, faCircleUser, faBars } from "@fortawesome/free-solid-svg-icons";
import zenscroll from "zenscroll";
import dynamic from "next/dynamic";

import styles from "./header.module.scss";
import useMobileDetect from "../../../helpers/mobile-detect";

function CustomDropdownMenu(props) {
  const { menu } = props;
  const [show, setShow] = useState(false);
  const currentDevice = useMobileDetect();
  const handleToggleMenu = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const isMobile = useMemo(() => currentDevice.isMobile(), [currentDevice]);

  const showMenu = (e) => {
    e.preventDefault();
    // on Mobile : not expand dropdown menu
    const element = document.querySelector(".navbar-collapse.show");
    !element && setShow(true);
  };

  const menuTitle = useMemo(() => {
    if (menu.value === "loginMenu") {
      return (
        <span href={menu.href} target="__blank" className="no-background no-border login d-flex align-items-center">
          <FontAwesomeIcon className="fs-5 me-2" icon={faCircleUser} />
          {menu.text}
        </span>
      );
    }
    return <a href={menu.href}>{menu.text}</a>;
  }, [menu]);

  return (
    <div
      className={`dropdown nav-item ${props.className || ""}`}
      onMouseOver={showMenu}
      onMouseLeave={() => {
        if (currentDevice.width > 768) {
          setShow(false);
        }
      }}
    >
      <span aria-haspopup="true" aria-expanded="false" id="basic-nav-dropdown" className={`dropdown-toggle nav-link ${styles["login-menu"]}`} role="button">
        {menuTitle}
        <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} className={"fa ms-2"} onClick={handleToggleMenu} style={{ maxWidth: 14 }} />
      </span>
      <div aria-labelledby="basic-nav-dropdown" className={`dropdown-menu ${show && "show"} ${isMobile && menu.value === "loginMenu" && "show"}`}>
        {props.children}
      </div>
    </div>
  );
}

function Header(props) {
  const router = useRouter();
  const currentDevice = useMobileDetect();
  const [load, setLoad] = useState(false);

  const oldAppUrl = useMemo(() => process.env.NEXT_PUBLIC_OLD_SITE || "#", []);
  const newAppUrl = useMemo(() => process.env.NEXT_PUBLIC_NEW_SITE || "#", []);

  const loginMenus = useMemo(
    () => ({
      value: "loginMenu",
      text: "Login",
      children: [
        {
          value: "version1",
          text: "VERSION 1: Before Nov 29, 2024",
          href: oldAppUrl,
        },
        {
          value: "version2",
          text: "VERSION 2: After Nov 29, 2024",
          href: newAppUrl,
        },
      ],
    }),
    []
  );

  const menus = useMemo(
    () => [
      {
        value: "about-the-diet-plan",
        text: "Our Programs",
        href: "",
        children: [],
      },
      {
        value: "testimonials",
        text: "Testimonials",
        href: "",
        children: [],
      },
      {
        value: "who-is-mike-dolce",
        text: "About Mike",
        href: "",
        children: [],
      },
      {
        value: "content",
        text: "Content",
        href: `${process.env.NEXT_PUBLIC_BASE}/blog`,
        children: [
          {
            value: "all",
            text: "ALL",
            href: `${process.env.NEXT_PUBLIC_BASE}/blog`,
          },
          {
            value: "podcasts",
            text: "PODCASTS",
            href: `${process.env.NEXT_PUBLIC_BASE}/blog/podcasts`,
          },
          {
            value: "blog",
            text: "BLOG",
            href: `${process.env.NEXT_PUBLIC_BASE}/blog/news`,
          },
          {
            value: "videos",
            text: "VIDEOS",
            href: `${process.env.NEXT_PUBLIC_BASE}/blog/video`,
          },
          {
            value: "weightcutting",
            text: "WEIGHT CUTTING",
            href: `${process.env.NEXT_PUBLIC_BASE}/blog/weightcutting`,
          },
        ],
      },
    ],
    [loginMenus]
  );

  useEffect(() => {
    if (!load) {
      setLoad(true);
    }
    AnimationMenu({ load: load });
  }, [load]);

  const renderMenu = () => {
    return menus.map((menu) =>
      menu.children.length > 0 ? (
        <CustomDropdownMenu key={menu.value} menu={menu}>
          {menu.children.map((item) => {
            return (
              <NavDropdown.Item key={item.value} href={item.href}>
                <span>{item.text}</span>
              </NavDropdown.Item>
            );
          })}
        </CustomDropdownMenu>
      ) : (
        <Nav.Item key={menu.value} className={router.pathname.includes(menu.value) ? "active" : ""}>
          <Nav.Link href={`/${menu.value}`}>{menu.text}</Nav.Link>
        </Nav.Item>
      )
    );
  };

  const LoginMenu = () => {
    return (
      <CustomDropdownMenu menu={loginMenus}>
        {loginMenus.children.map((item) => {
          return (
            <NavDropdown.Item key={item.value} href={item.href}>
              <span>{item.text}</span>
            </NavDropdown.Item>
          );
        })}
      </CustomDropdownMenu>
    );
  };

  const LoginMobileMenu = () => {
    const [show, setShow] = useState(false);

    const handleToggleMenu = (e) => {
      e.preventDefault();
      setShow(!show);
    };

    return (
      <div className="mobile-login-bar">
        <div onClick={handleToggleMenu}>
          <FontAwesomeIcon className="fs-5 me-2" icon={faCircleUser} />
          <span>Login</span>
        </div>
        <div className={`dropdown-menu mobile-login-bar--items ${show && "show"}`}>
          {loginMenus.children.map((item) => {
            return (
              <NavDropdown.Item key={item.value} href={item.href}>
                <span>{item.text}</span>
              </NavDropdown.Item>
            );
          })}
        </div>
      </div>
    );
  };

  const handleMenuMobile = (e) => {
    const element = document.querySelector(".screen-overlay");
    const topMenu = document.querySelector(".top-menu");
    if (element) {
      if (element.classList.contains("show")) {
        element.classList.remove("show");
      } else {
        element.classList.add("show");
      }
    }
    if (topMenu) {
      if (topMenu.classList.contains("show")) {
        topMenu.classList.remove("show");
      } else {
        topMenu.classList.add("show");
      }
    }
  };

  const scrollToElement = (e) => {
    e.preventDefault();
    const element = document.querySelector("#choose-program");
    if (element) {
      zenscroll.to(element);
    } else {
      window.location = "/about-the-diet-plan#choose-program";
    }
  };

  return (
    <header id="main-menu" className={`fixed-top ${styles.header}`}>
      <div className={"screen-overlay"}></div>
      <div className="container-fluid">
        <Navbar bg="transparent" expand="lg" className={styles["top-menu"]}>
          <Navbar.Brand href="/">
            <Image className={styles.logo} src="/static/images/logo.svg" alt="Dolce diet" width="199" height="53" />
          </Navbar.Brand>
          <div className="text-right d-flex d-lg-none align-items-center">
            <button className="btn mobile bg-red get-started me-2" onClick={scrollToElement}>
              Get started
            </button>
            <LoginMobileMenu />
            {/* <a className="login-mobile no-border px-2" rel="noreferrer" target={"_blank"} href={newAppUrl} aria-label="login on mobile">
              <FontAwesomeIcon className="fs-5" icon={faCircleUser} />
            </a> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-dark no-border py-0 py-1 m-0" onClick={handleMenuMobile}>
              <FontAwesomeIcon className="text-white" icon={faBars}></FontAwesomeIcon>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id="basic-navbar-nav" className="mobile-offcanvas">
            <Nav className="ms-5 me-auto flex-wrap flex-fill">
              <Nav.Item>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-dark no-border close-menu" onClick={handleMenuMobile}>
                  <Image alt="nav-close" src="/static/images/nav-close.svg" width={19} height={18} />
                </Navbar.Toggle>
              </Nav.Item>
              {renderMenu()}
            </Nav>
            <Form className="d-flex justify-content-end align-items-center gap-2 flex-nowrap">
              <a className="btn no-border d-flex align-items-center" href="https://shop.thedolcediet.com/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="fs-5 me-2" icon={faCartShopping} />
                Browse Shop
              </a>
              <LoginMenu />
              <div className="text-right mt-3 mt-lg-0">
                <Button variant="danger" className="btn bg-red get-started" onClick={scrollToElement}>
                  Get started
                </Button>
              </div>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
}

CustomDropdownMenu.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any,
};

export default Header;

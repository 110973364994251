import React, { useState, useRef, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import TextField, { HelperText, Input } from '@material/react-text-field'
import { useForm, Controller } from 'react-hook-form'
import axios from 'helpers/axios'
import DolceLoading from '../loading'

import styles from './subscribe.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

export default function Index (props) {
  const input = useRef()
  const [status, setStatus] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const {
    handleSubmit,
    errors,
    formState,
    control,
    setError
  } = useForm({ mode: 'onChange' })
  const { touched } = formState

  const onSubmit = (data) => {
    setShowLoading(true)
    axios.post(API_NEWSLETTER, {
      email: data.email,
      firstName: null,
      lastName: null,
      type: 'AC',
      list: ['guest_list'],
      tags: ['newsletter_subscribers']
    }).then((response) => {
      setStatus(true)
    })
      .catch((ex) => {
        let message = ex.message
        if (ex.response.data) {
          message = ex.response.data.message.map(msg => {
            if (msg.error_key === 'contacts.api.conflict') {
              return 'This email has been subscribed already!'
            }
            return 'Error! Could not subscribe this email!'
          }).join(', ')
          setError('email', {
            type: 'manual',
            message: message
          })
        }
      }).finally(() => {
        setShowLoading(false)
      })
  }

  useEffect(() => {
    if (input.current) {
      input.current.inputElement.setAttribute('placeholder', 'Your email address')
    }
  }, [input])

  return (
    <section className={styles.subscribe}>
      <div className='container d-lg-flex align-items-center justify-content-center'>
        <h4 className={`${styles.subscribe__title} mt-0`}>
          Like Mike’s content? <br className={'d-md-none'} /> Sign up to the weekly newsletter
        </h4>
        <Form name='subscribe-form'
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={`${styles.subscribe__form} ${status ? 'submitted' : ''}`}>
          <div className='form-group'>
            <TextField
              label={'Your email address'}
              helperText={
                errors.email && (
                  <HelperText
                    isValidationMessage={true}
                    isValid={false}
                    persistent={true}
                  >
                    {errors.email.type === 'required' &&
                    'Please enter your email address'}
                    {errors.email.type === 'pattern' &&
                    'Please enter a valid email address'}
                    {errors.email.type === 'manual' &&
                    `${errors.email.message}`}
                  </HelperText>
                )
              }
              trailingIcon={
                touched &&
                errors.email ? <FontAwesomeIcon className="fs-5" icon={faCircleExclamation} />
                  : (status && <FontAwesomeIcon className="fs-5" icon={faCircleCheck} />)
              }
            >
              <Controller
                id={'email'}
                name={'email'}
                defaultValue={''}
                control={control}
                rules={{ required: true, pattern: EMAIL_REGEX, maxLength: 253 }}
                as={<Input isValid={!(touched && errors.email)} placeholder={'Your email address'} ref={input} />}
              />
            </TextField>
          </div>
          <div className={`position-relative ${styles['subscribe__submit-btn-wrapper']}`}>
            <button type='submit'
              className={`btn ${status ? 'submitted' : ''}`}>
              {!status ? 'subscribe' : 'subscribed!'}
            </button>
            <DolceLoading isShow={showLoading} />
          </div>
        </Form>
      </div>
    </section>
  )
}
